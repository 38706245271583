import Image from 'next/image';
import { motion } from 'framer-motion';
import { GLOBAL_OPENER_DELAY } from '@/src/constants/delay';

type Props = {
  children: React.ReactNode;
};

const OffsideWrapper = ({ children }: Props) => {
  return (
    <div className='before:-z-11 relative overflow-hidden py-14 before:absolute before:inset-0 before:bg-black/20'>
      <div className='absolute inset-0 -z-[10] bg-black/60' />
      <Image
        className='-z-20 object-cover object-[50%_60%]'
        src='/images/grass-corner.jpg'
        fill
        sizes='1280px'
        alt=''
        priority
      />
      <motion.div
        // className='origin-left'
        initial={{ x: -50, y: 300, rotateZ: 10, transformOrigin: 'left' }}
        animate={{ x: 0, y: 0, rotateZ: 0, transformOrigin: 'left' }}
        transition={{ type: 'spring', bounce: 0.15, delay: GLOBAL_OPENER_DELAY, duration: 1 }}
      >
        <Image
          className='absolute -left-8 -z-[9] sm:-left-16 md:-left-24'
          src='/images/offside.png'
          width={1100}
          height={653}
          priority
          alt=''
        />
      </motion.div>
      <div className='container relative z-10 grid min-h-[80vh] grid-cols-5 p-2' dir='ltr'>
        <div className='col-span-full lg:col-span-2 xl:col-span-3'></div>
        <div className='col-span-full lg:col-span-3 xl:col-span-2'>{children}</div>
      </div>
    </div>
  );
};

export default OffsideWrapper;
